// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opt-in-messaging {
  display: flex;
  flex-direction: row;
  color: #1a2026;
  font-size: 13px;
  line-height: 150%;
  gap: 8px;
  width: 101%;
}
.opt-in-messaging__checkbox input {
  margin: 0;
  width: 16px;
  height: 16px;
}
.opt-in-messaging__message {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/opt-in-experience-messaging/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,QAAA;EAEA,WAAA;AAHF;AAKE;EAEI,SAAA;EACA,WAAA;EACA,YAAA;AAJN;AAQE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AANJ","sourcesContent":["@import '../../../style/colors.less';\n\n.opt-in-messaging {\n  display: flex;\n  flex-direction: row;\n\n  color: @TextIconInteractive-base;\n  font-size: 13px;\n  line-height: 150%;\n  gap: 8px;\n\n  width: 101%;\n\n  &__checkbox {\n    input {\n      margin: 0;\n      width: 16px;\n      height: 16px;\n    }\n  }\n\n  &__message {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
