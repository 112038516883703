import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import InputField from '../shared/input-field';
import './index.less';
import FormHeader from '../shared/form-header';
import Button from '../shared/button';
import CountryStateOptInFields from '../shared/country-state-opt-in-fields';
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
  JOB_TITILE_FIELD,
  MARKETO_FORM_TYPES,
  COUNTRY_FIELD,
  STATE_FIELD,
} from '../../constants/form-fields';
import {REQUIRED_FORM_FIELD_TEXT} from '../../constants/errors';
import {CREATE_AN_ACCOUNT_TITLE} from '../../constants/page-title';
import {
  getStateOptions,
  COUNTRY_STATE_MAP,
  IMPLIED_OPTIN_COUNTRIES,
} from '../../constants/country-state';
import {focusField} from '../../utils/form-helpers';
import {
  ExternalLink,
  PRODUCT_TERMS_OF_USE_S,
  WEBSITE_TERMS_OF_USE_S,
} from '../shared/external-link';

const TEAM_SIGNUP_FORM_ORDER = [
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
  JOB_TITILE_FIELD,
];

const TeamMemberSignupForm = ({
  email,
  invitationId,
  setBody,
  isLegacy = true,
}) => {
  if (!email || !invitationId) {
    return;
  }
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    setStateCode('');
    setOptIn(false);
  }, [countryCode]);

  useEffect(() => {
    if (Object.keys(fieldErrors).length) {
      focusField(fieldErrors, TEAM_SIGNUP_FORM_ORDER);
    }
  }, [fieldErrors]);

  const validFields = () => {
    const errors = {};
    if (!firstName) {
      errors[FIRST_NAME_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!lastName) {
      errors[LAST_NAME_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!phone) {
      errors[PHONE_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!jobTitle) {
      errors[JOB_TITILE_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!countryCode) {
      errors[COUNTRY_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (
      !stateCode &&
      countryCode &&
      getStateOptions(countryCode).length !== 0
    ) {
      errors[STATE_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    setFieldErrors(errors);
    return !Object.keys(errors).length;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setFieldErrors({});
    if (!validFields()) {
      return;
    }
    const optInValue =
      optIn || IMPLIED_OPTIN_COUNTRIES.includes(countryCode) ? 'Yes' : 'No';
    const body = {
      form_type: MARKETO_FORM_TYPES.TEAM_MEMBER_SIGNUP,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      job_title: jobTitle,
      inviteId: invitationId,
      teamMember: true,
      country_code: countryCode || '',
      state_code: stateCode || '',
      country_integration_value:
        COUNTRY_STATE_MAP[countryCode]?.integration_value || '',
      state_integration_value:
        COUNTRY_STATE_MAP[countryCode]?.state_map[stateCode]
          ?.integration_value || '',
      opt_in: optInValue,
    };
    if (isLegacy) {
      setBody(body);
      return navigate('/team-member/confirm-password');
    }
    setBody(body);
  };

  return (
    <form className="team-member-signup-form" onSubmit={submitForm}>
      <FormHeader current={1} max={2} headerText={CREATE_AN_ACCOUNT_TITLE} />
      {isLegacy && (
        <h4 className="team-member-signup-form__subtext">
          Hi {email}, finish creating your account so you can collaborate with
          your teammates.
        </h4>
      )}
      <div className="team-member-signup-form__fields">
        <InputField
          inputFieldKey={FIRST_NAME_FIELD}
          labelText="First name"
          inputPlaceholder="John"
          isRequired
          value={firstName}
          onChange={(value) => {
            setFirstName(value);
            if (fieldErrors[FIRST_NAME_FIELD]) {
              setFieldErrors({...fieldErrors, [FIRST_NAME_FIELD]: ''});
            }
          }}
          error={fieldErrors[FIRST_NAME_FIELD]}
        />
        <InputField
          inputFieldKey={LAST_NAME_FIELD}
          labelText="Last name"
          inputPlaceholder="Doe"
          isRequired
          value={lastName}
          onChange={(value) => {
            setLastName(value);
            if (fieldErrors[LAST_NAME_FIELD]) {
              setFieldErrors({...fieldErrors, [LAST_NAME_FIELD]: ''});
            }
          }}
          error={fieldErrors[LAST_NAME_FIELD]}
        />
        <InputField
          inputFieldKey={EMAIL_FIELD}
          labelText="Business Email"
          inputPlaceholder="name@companyname.com"
          isRequired
          value={email}
          uneditable={true}
          error={fieldErrors[EMAIL_FIELD]}
        />
        <InputField
          inputFieldKey={PHONE_FIELD}
          labelText="Phone"
          inputPlaceholder="(000) 000-0000"
          isRequired
          value={phone}
          onChange={(value) => {
            setPhone(value);
            if (fieldErrors[PHONE_FIELD]) {
              setFieldErrors({...fieldErrors, [PHONE_FIELD]: ''});
            }
          }}
          error={fieldErrors[PHONE_FIELD]}
        />
        <InputField
          inputFieldKey={JOB_TITILE_FIELD}
          labelText="Job title"
          inputPlaceholder="Enter job title"
          isRequired
          value={jobTitle}
          onChange={(value) => {
            setJobTitle(value);
            if (fieldErrors[JOB_TITILE_FIELD]) {
              setFieldErrors({...fieldErrors, [JOB_TITILE_FIELD]: ''});
            }
          }}
          error={fieldErrors[JOB_TITILE_FIELD]}
        />
      </div>
      <CountryStateOptInFields
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        stateCode={stateCode}
        setStateCode={setStateCode}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
        optIn={optIn}
        setOptIn={setOptIn}
      />
      <span className="team-member-signup-form__terms">
        By signing up, you agree to our{' '}
        <ExternalLink type={PRODUCT_TERMS_OF_USE_S} />
        {' and '}
        <ExternalLink type={WEBSITE_TERMS_OF_USE_S} />.
      </span>
      <div className="team-member-signup-form__button">
        <Button
          inputFieldKey="next"
          className="team-member-signup-form__next-button"
          text="Next"
          type="submit"
        ></Button>
      </div>
    </form>
  );
};

export default TeamMemberSignupForm;
