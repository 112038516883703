export const MESSAGE_USER_ACCOUNT_SUSPENDED_TEXT = 'Account is suspended.';

export const UNAUTHORIZED_CODE = 'Unauthorized';
export const UNAUTHORIZED_TEXT = 'Invalid email or password. Try again.';
export const LOCKED_CODE = 'userAccountLockedOut';
export const SUSPENDED_CODE = 'Account Deactivated';
export const PRO_SUBSCRIPTION_ENDED = 'Ecommerce plan subscription ended';

export const GENERIC_ERROR_TEXT = 'Something went wrong.';
export const GENERIC_LOGIN_ERROR_TEXT =
  'There was a problem logging you in. Try again.';
export const REQUIRED_FORM_FIELD_TEXT = 'Required Field';
export const INVALID_EMAIL_ERROR_TEXT = 'Valid email address required';
export const ENTER_VALID_EMAIL_TEXT = 'Enter a valid email address';

export const FAILURE_SAML = 'FAILURE_SAML';
export const FAILURE_SAML_LIMIT_REACHED = 'FAILURE_SAML_LIMIT_REACHED';
export const FAILURE_AUTH_APP = 'FAILURE_AUTH_APP';
export const FAILURE_GENERAL = 'FAILURE_GENERAL';
export const FAILURE_PAGE_NOT_FOUND = 'FAILURE_PAGE_NOT_FOUND';
