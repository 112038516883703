import React from 'react';

import {
  ExternalLink,
  PRIVACY_POLICY_S,
  UNSUBSCRIBING,
  CONTACT_US_PRIVACY,
} from '../external-link';
import {OPT_IN_FIELD} from '../../../constants/form-fields';
import {IMPLIED_OPTIN_COUNTRIES} from '../../../constants/country-state';

import './index.less';

const OptInExperienceMessaging = ({country_code, optIn, setOptIn}) => {
  if (!country_code || country_code === 'US') {
    return null;
  }
  const showCheckbox = !IMPLIED_OPTIN_COUNTRIES.includes(country_code);
  const showAddress = country_code === 'CA';
  return (
    <div className="opt-in-messaging">
      {showCheckbox && (
        <div className="opt-in-messaging__checkbox">
          <input
            id={`field-${OPT_IN_FIELD}`}
            type="checkbox"
            checked={optIn}
            onChange={() => setOptIn(!optIn)}
          />
        </div>
      )}
      <div className="opt-in-messaging__message">
        <span>
          I agree to receive electronic messages from Cvent, Inc. about future
          whitepapers, webcasts, videos, products, events and more. I understand
          I can withdraw my consent by <ExternalLink type={UNSUBSCRIBING} />
          {` `}at any time.
        </span>
        <span>
          Please refer to our <ExternalLink type={PRIVACY_POLICY_S} /> or{' '}
          <ExternalLink type={CONTACT_US_PRIVACY} /> for more details.
        </span>
        {showAddress && (
          <span>
            Cvent, Inc. 1765 Greensboro Station Place
            <br />
            7th Floor, Tysons Corner, VA 22102
          </span>
        )}
      </div>
    </div>
  );
};

OptInExperienceMessaging.propTypes = {
  country_code: PropTypes.string,
  optIn: PropTypes.bool,
  setOptIn: PropTypes.func,
};

export default OptInExperienceMessaging;
