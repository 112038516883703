import {createBrowserRouter} from 'react-router-dom';
import Root from './routes/root';
import LoginPage from './routes/login-page';
import ProPage from './routes/pro-page';
import SignupPage from './routes/signup-page';
import PlanSelectionPage from './routes/plan-selection-page';
import VerifyEmailPage from './routes/verify-email-page';
import ResetPasswordPage from './routes/reset-password-page';
import BillingPage from './routes/billing-page';
import BillingSuccessPage from './routes/billing-page-success';
import SuccessPage from './routes/success-page';
import ForgotPasswordPage from './routes/forgot-password-page';
import ResetPasswordSuccessPage from './routes/reset-password-success-page';
import FailurePage from './routes/failure-page';
import TeamMemberSignupPage from './routes/team-member-signup-page';
import TeamMemberConfirmPasswordPage from './routes/team-member-confirm-password-page';
import JoinTeamPage from './routes/join-team-page';
import SwitchTeamPage from './routes/switch-team-page';
import JoinEventPage from './routes/join-event-page';
import ProUpgradePage from './routes/pro-upgrade-page';
import {
  FAILURE_SAML,
  FAILURE_AUTH_APP,
  FAILURE_GENERAL,
  FAILURE_PAGE_NOT_FOUND,
  FAILURE_SAML_LIMIT_REACHED,
} from './constants/errors';
import SamlRedirectForm from './routes/saml-redirect-form';
import OauthAuthorizeForm from './routes/oauth-authorize-form';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <FailurePage type={FAILURE_PAGE_NOT_FOUND} />,
    children: [
      ...['/', '/login', 'signin'].map((path) => ({
        path,
        element: <LoginPage />,
      })),
      {
        path: '/pro',
        element: <ProPage />,
      },
      {
        path: '/pro/upgrade',
        element: <ProUpgradePage />,
      },
      {
        path: '/signup',
        element: <PlanSelectionPage />,
      },
      {
        path: '/signup/form',
        element: <SignupPage />,
      },
      {
        path: '/verify-email',
        element: <VerifyEmailPage />,
      },
      {
        path: '/success',
        element: <SuccessPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/billing',
        element: <BillingPage />,
      },
      {
        path: '/billing-success',
        element: <BillingSuccessPage />,
      },
      {
        path: '/team-member/signup',
        element: <TeamMemberSignupPage />,
      },
      {
        path: '/join-team/:invitationCode',
        element: <JoinTeamPage />,
      },
      {
        path: '/switch-team/:invitationCode',
        element: <SwitchTeamPage />,
      },
      {
        path: '/join-event/:invitationCode',
        element: <JoinEventPage />,
      },
      {
        path: '/team-member/confirm-password',
        element: <TeamMemberConfirmPasswordPage />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: '/reset-password-success',
        element: <ResetPasswordSuccessPage />,
      },
      {
        path: '/saml/:idpName',
        element: <SamlRedirectForm />,
      },
      {
        path: '/saml-error',
        element: <FailurePage type={FAILURE_SAML} />,
      },
      {
        path: '/saml-overage',
        element: <FailurePage type={FAILURE_SAML_LIMIT_REACHED} />,
      },
      {
        path: '/oauth/authorize',
        element: <OauthAuthorizeForm />,
      },
      {
        path: '/oauth-authorize-error',
        element: <FailurePage type={FAILURE_AUTH_APP} />,
      },
      ...['/failure-general', '/request-error'].map((path) => ({
        path,
        element: <FailurePage type={FAILURE_GENERAL} />,
      })),
    ],
  },
]);

export default router;
