import classNames from 'classnames';
import {
  FREE_PLAN_NAME,
  FREE_BASE_PRICE,
  FREE_PLAN_DETAILS,
  PRO_PLAN_NAME,
  PRO_BASE_PRICE,
  PRO_PLAN_DETAILS,
} from '../../constants/plan';
import {getOneMonthFromToday} from '../../utils/date-helpers';
import {
  ExternalLink,
  PRIVACY_POLICY_S,
  PRODUCT_TERMS_OF_USE_S,
  WEBSITE_TERMS_OF_USE_S,
} from '../shared/external-link';
import './index.less';

export const PlanDetail = ({planType = FREE_PLAN_NAME}) => {
  const isFree = planType === FREE_PLAN_NAME;
  const planTitle = isFree ? (
    <div>
      <span>{planType}</span> Trial
    </div>
  ) : (
    <div>
      <span>{planType}</span> plan
    </div>
  );
  const planPrice = isFree ? FREE_BASE_PRICE : PRO_BASE_PRICE;
  const planDetails = isFree ? FREE_PLAN_DETAILS : PRO_PLAN_DETAILS;
  return (
    <div className="plan-detail">
      <h2
        className={
          isFree
            ? 'plan-detail__title'
            : 'plan-detail__title plan-detail__title-pro'
        }
      >
        Plan Summary
      </h2>
      <div
        className={
          isFree
            ? 'plan-detail__main'
            : 'plan-detail__main plan-detail__main-pro'
        }
      >
        <div
          className={
            isFree
              ? 'plan-detail__plan'
              : 'plan-detail__plan plan-detail__plan-pro'
          }
        >
          {planTitle}
          <div>{`$${planPrice}/MONTH`}</div>
        </div>
        <div
          className={
            isFree
              ? 'plan-detail__limit'
              : 'plan-detail__limit plan-detail__limit-pro'
          }
        >
          {planDetails &&
            planDetails.map((detail, index) => <div key={index}>{detail}</div>)}
        </div>
      </div>
    </div>
  );
};

export const PriceDetails = ({tax}) => {
  return (
    <>
      <div className="price-details">
        <div className="price-details__row">
          <span>Subtotal</span>
          <span>{`$${PRO_BASE_PRICE}`}</span>
        </div>
        <div className="price-details__row price-details__row-bold">
          <span>Charged today</span>
          <span>
            {`$${PRO_BASE_PRICE}`}
            <span className="price-details__row-tax">{` + ${
              tax || 'applicable tax'
            }`}</span>
          </span>
        </div>
      </div>
      <div className="billing-date">
        <span id="billing-date">
          Next billing date: {getOneMonthFromToday()}
        </span>
        <section>
          Before starting your plan, you agree to the
          <br />
          <ExternalLink type={PRIVACY_POLICY_S} />
          {', '}
          <ExternalLink type={PRODUCT_TERMS_OF_USE_S} />
          {', and '}
          <ExternalLink type={WEBSITE_TERMS_OF_USE_S} />
          . You will be
          <br />
          charged for this plan today and then monthly
          <br />
          unless you cancel or change it.
        </section>
      </div>
    </>
  );
};

const PlanSummary = ({
  planType = FREE_PLAN_NAME,
  tax,
  billingDate = 'September 7, 2023',
}) => {
  const isFree = planType === FREE_PLAN_NAME;
  const planSummaryClassname = classNames('plan-summary', {
    'plan-summary-pro': planType === PRO_PLAN_NAME,
  });
  return (
    <div className={planSummaryClassname}>
      <PlanDetail planType={planType} />
      {!isFree && <PriceDetails tax={tax} billingDate={billingDate} />}
    </div>
  );
};

export default PlanSummary;
