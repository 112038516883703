import './index.less';

const AdContainer = () => {
  return (
    <div className="right-marketing-container">
      <a
        href="https://web.cvent.com/event/428567fa-cc9f-4073-96a3-1240f6f29340/regProcessStep1?utm_campaign=WGYC_Anyeventeveryevent--OD_AM--beyond_basics_event_strategy&utm_medium=internal_web&utm_source=login_ads&utm_prod=SHS&utm_reg=NE&utm_aud=ALL&utm_eng=EN&utm_ver=ALLEC&utm_per=ALL&utm_ptype=DG-MOFU&utm_bus=MMENTTPP"
        aria-label="Discover how Cvent Exchange solutions can save you time and elevate your events."
        target="_blank"
      >
        <img
          className="hs-cta-img-top"
          src="https://assets.socialtables.com/ads/login-ad-1.jpg"
          alt="Discover how Cvent Exchange solutions can save you time and elevate your events."
        />
      </a>
      <a
        href="https://www.cvent.com/en/resource/hospitality/innovative-trends-venues-strategies-maximize-group-bookings?utm_campaign=MTM--eBook--uv-trends-group-bookings&utm_medium=internal_web&utm_source=login_ads&utm_prod=CED&utm_reg=NA&utm_aud=ALL&utm_eng=ALL&utm_ver=UV&utm_per=NonP&utm_ptype=DG-TOFU&utm_bus=HC"
        aria-label="Innovative Trends for Venues: Strategies to Maximize Group Bookings"
        target="_blank"
      >
        <img
          className="hs-cta-img-bottom"
          src="https://assets.socialtables.com/ads/03062025-Innovative-Trends-for-Venues.png"
          alt="Innovative Trends for Venues: Strategies to Maximize Group Bookings"
        />
      </a>
    </div>
  );
};

export default AdContainer;
