import React from 'react';

import Dropdown from '../dropdown';
import OptInExperienceMessaging from '../opt-in-experience-messaging';
import {COUNTRY_FIELD, STATE_FIELD} from '../../../constants/form-fields';
import {
  COUNTRY_OPTIONS,
  getStateOptions,
} from '../../../constants/country-state';
import './index.less';

const CountryStateOptInFields = ({
  countryCode,
  setCountryCode,
  stateCode,
  setStateCode,
  fieldErrors,
  setFieldErrors,
  optIn,
  setOptIn,
}) => {
  const showStateDropdown = () => {
    return !countryCode || getStateOptions(countryCode).length !== 0;
  };

  return (
    <>
      <div className="country-state-fields">
        <Dropdown
          inputFieldKey={COUNTRY_FIELD}
          labelText="Country/Region"
          options={COUNTRY_OPTIONS}
          placeholder="Select Country/Region"
          isRequired
          selectedOption={countryCode}
          onSelectionChange={(value) => {
            setCountryCode(value);
            if (fieldErrors[COUNTRY_FIELD]) {
              setFieldErrors({...fieldErrors, [COUNTRY_FIELD]: ''});
            }
          }}
          error={fieldErrors[COUNTRY_FIELD]}
          dividerIndex={5}
        />
        {showStateDropdown() && (
          <Dropdown
            inputFieldKey={STATE_FIELD}
            labelText="State/Province"
            options={getStateOptions(countryCode)}
            placeholder="Select State/Province"
            isRequired
            isDisabled={!countryCode}
            selectedOption={stateCode}
            onSelectionChange={(value) => {
              setStateCode(value);
              if (fieldErrors[STATE_FIELD]) {
                setFieldErrors({...fieldErrors, [STATE_FIELD]: ''});
              }
            }}
            error={fieldErrors[STATE_FIELD]}
          />
        )}
      </div>
      <OptInExperienceMessaging
        country_code={countryCode}
        optIn={optIn}
        setOptIn={setOptIn}
      />
    </>
  );
};
export default CountryStateOptInFields;
