import {
  NAME_FIELD,
  ADDRESS_LINE1_FIELD,
  ADDRESS_LINE2_FIELD,
  ADDRESS_COUNTRY_FIELD,
  ADDRESS_ZIP,
  ADDRESS_CITY,
  ADDRESS_STATE,
} from './billings';

export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const EMAIL_FIELD = 'email';
export const PHONE_FIELD = 'phone';
export const COUNTRY_FIELD = 'countryCode';
export const STATE_FIELD = 'stateCode';
export const OPT_IN_FIELD = 'optIn';
export const JOB_TITILE_FIELD = 'jobTitle';
export const BUSINESS_NAME_FIELD = 'businessName';
export const BUSINESS_TYPE_FIELD = 'businessType';
export const SQFT_FIELD = 'sqft';
export const PASSWORD_FIELD = 'password';
export const CONFIRM_PASSWORD_FIELD = 'confirmPassword';

export const HOTEL_VALUE = 'hotel';
export const VENUE_VALUE = 'venue';
export const CVB_DMO_VALUE = 'cvbDmo';

export const BUSINESS_TYPE_OPTIONS = [
  {
    value: HOTEL_VALUE,
    label: 'Hotel',
    description: 'I work for a hotel where we host events.',
  },
  {
    value: VENUE_VALUE,
    label: 'Venue',
    description: 'I own or work for a venue where we host events.',
  },
  {
    value: 'student',
    label: 'Student',
    description: 'I attend an educational institution.',
  },
  {
    value: 'universityCollege',
    label: 'University or College',
    description: 'I work for a university or college and manage events.',
  },
  {
    value: CVB_DMO_VALUE,
    label: 'CVB/DMO',
    description:
      'I work for an organization that promotes a location for events.',
  },
  {
    value: 'eventPlanner',
    label: 'Event Planner or Marketer',
    description:
      'I am a professional, unaffiliated with a hotel, venue, university or college.',
  },
  {
    value: 'host',
    label: 'Host',
    description: 'I am planning my own private event.',
  },
  {
    value: 'serviceProvider',
    label: 'Service Provider',
    description: 'I am a vendor that provides services for events.',
  },
];

export const FIELD_AUTOCOMPLETE_MAP = {
  [FIRST_NAME_FIELD]: 'given-name',
  [LAST_NAME_FIELD]: 'family-name',
  [EMAIL_FIELD]: 'email',
  [PHONE_FIELD]: 'tel',
  [JOB_TITILE_FIELD]: 'organization-title',
  [BUSINESS_NAME_FIELD]: 'organization',
  [PASSWORD_FIELD]: 'new-password',
  [CONFIRM_PASSWORD_FIELD]: 'new-password',
  [NAME_FIELD]: 'cc-name', // 'cc-name' is for 'credit card name field
  [ADDRESS_LINE1_FIELD]: 'address-line1',
  [ADDRESS_LINE2_FIELD]: 'address-line2',
  [ADDRESS_COUNTRY_FIELD]: 'country',
  [ADDRESS_ZIP]: 'postal-code',
  [ADDRESS_CITY]: 'address-level2',
  [ADDRESS_STATE]: 'address-level1',
};

export const SQFT_OPTIONS = [
  {label: 'Less than 1,000 Sq. Ft.', value: 'Less than 1,000 Sq. Ft.'},
  {label: '1,000 - 1,999 Sq. Ft.', value: '1,000 - 1,999 Sq. Ft.'},
  {label: '2,000 - 4,999 Sq. Ft.', value: '2,000 - 4,999 Sq. Ft.'},
  {label: '5,000 - 9,999 Sq. Ft.', value: '5,000 - 9,999 Sq. Ft.'},
  {label: '10,000 - 19,999 Sq. Ft.', value: '10,000 - 19,999 Sq. Ft.'},
  {label: '20,000 - 49,999 Sq. Ft.', value: '20,000 - 49,999 Sq. Ft.'},
  {label: '50,000 - 99,999 Sq. Ft.', value: '50,000 - 99,999 Sq. Ft.'},
  {label: '100,000 Sq. Ft. or more', value: '100,000 Sq. Ft. or more'},
];

/*
  ST_Property_Success
  Hotel [HC]
  Venue [HC] (per Myllisa need because different selling teams)
  CVB/DMO [HC]

  ST_Freemium_Success
  Event Planner or Marketer [EC]
  University or College [EC]
  Service Provider (A/V, caterer, etc.) [EC]
  Host (Weddings, social gatherings, etc.) [excluded]
  Student [excluded]
  Those marked as excluded is just for me to know not to create a task, they are still going to capture the record just not create a sales task on them.
*/
export const HC_BUSINESS_TYPES_LIST = [HOTEL_VALUE, VENUE_VALUE, CVB_DMO_VALUE];

export const MARKETO_FORM_TYPES = {
  FREEMIUM_SUCCESS: 'FREEMIUM_SUCCESS',
  FREEMIUM_SUCCESS_PRO: 'FREEMIUM_SUCCESS_PRO',
  PROPERTY_REGISTRATION: 'PROPERTY_REGISTRATION',
  PROPERTY_REGISTRATION_PRO: 'PROPERTY_REGISTRATION_PRO',
  COLLAB_SIGNUP: 'COLLAB_SIGNUP',
  TEAM_MEMBER_SIGNUP: 'TEAM_MEMBER_SIGNUP',
};
