import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  FAILURE_SAML,
  FAILURE_AUTH_APP,
  FAILURE_GENERAL,
  FAILURE_PAGE_NOT_FOUND,
  FAILURE_SAML_LIMIT_REACHED,
} from '../../constants/errors';
import FailureSaml from '../../assets/failure-saml.svg';
import FailureAuthApp from '../../assets/failure-auth-app.svg';
import FailureGeneral from '../../assets/failure-general.svg';
import Button from '../../components/shared/button';
import Footer from '../../components/footer';
import {updatePageTitle} from '../../utils/page-title-helpers';
import './index.less';

const FAILURE_PAGE_CONFIG = {
  [FAILURE_SAML]: {
    failureImage: FailureSaml,
    failureTitle: 'This isn’t right',
    failureMessage:
      'We were unable to log you in using Single Sign On (SSO). Please try logging in without SSO.',
    actionText: 'Log in without SSO',
  },
  [FAILURE_SAML_LIMIT_REACHED]: {
    failureImage: FailureSaml,
    failureTitle: 'Unable to Provision User',
    failureMessage: 'No available seats. Contact support if this is in error.',
    actionText: 'Log in',
  },
  [FAILURE_AUTH_APP]: {
    failureImage: FailureAuthApp,
    failureTitle: 'Unable to authorize App',
    failureMessage: 'The requested OAuth authorization code flow failed.',
    actionText: 'Return to Login',
  },
  [FAILURE_GENERAL]: {
    failureImage: FailureGeneral,
    failureTitle: 'This isn’t right',
    failureMessage: 'We were unable to complete this request.',
    actionText: 'Log in',
  },
  [FAILURE_PAGE_NOT_FOUND]: {
    failureImage: FailureSaml,
    failureTitle: 'Oh no! This page cannot be found.',
    failureMessage: '',
    actionText: 'Back to home',
  },
};

const FailurePage = ({type = FAILURE_GENERAL}) => {
  const navigate = useNavigate();
  const {failureImage, failureTitle, failureMessage, actionText} =
    FAILURE_PAGE_CONFIG[type];

  useEffect(() => {
    updatePageTitle(failureTitle);
  }, []);

  return (
    <div className="failure-page">
      <img
        src={failureImage}
        className="failure-page__image"
        alt="error image"
        aria-hidden="true"
      />
      <h1 className="failure-page__heading">{failureTitle}</h1>
      <span className="failure-page__message">{failureMessage}</span>
      <Button
        inputFieldKey="failure_cta"
        className="failure-page__button"
        text={actionText}
        onClick={() => navigate('/')}
      />
      <Footer />
    </div>
  );
};

export default FailurePage;
