import './index.less';

const Background = () => {
  return (
    <div className="background">
      <img
        src="https://assets.socialtables.com/login-background.png"
        alt="background image"
      />
    </div>
  );
};

export default Background;
