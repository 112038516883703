/* eslint-disable max-lines */
export const COUNTRY_STATE_MAP = {
  AD: {
    integration_value: 'AD',
    label: 'Andorra',
    state_map: {},
  },
  AE: {
    integration_value: 'AE',
    label: 'United Arab Emirates',
    state_map: {},
  },
  AF: {
    integration_value: 'AF',
    label: 'Afghanistan',
    state_map: {},
  },
  AG: {
    integration_value: 'AG',
    label: 'Antigua and Barbuda',
    state_map: {},
  },
  AL: {
    integration_value: 'Albania',
    label: 'Albania',
    state_map: {},
  },
  AM: {
    integration_value: 'Armenia',
    label: 'Armenia',
    state_map: {},
  },
  AO: {
    integration_value: 'AO',
    label: 'Angola',
    state_map: {},
  },
  AR: {
    integration_value: 'AR',
    label: 'Argentina',
    state_map: {},
  },
  AS: {
    integration_value: 'AS',
    label: 'American Samoa',
    state_map: {},
  },
  AT: {
    integration_value: 'AT',
    label: 'Austria',
    state_map: {},
  },
  AU: {
    integration_value: 'AU',
    label: 'Australia',
    state_map: {
      ACT: {
        integration_value: 'ACT(AUS)',
        label: 'Australian Capital Territory',
      },
      NSW: {
        integration_value: 'NSW(AUS)',
        label: 'New South Wales',
      },
      NT: {
        integration_value: 'NT(AUS)',
        label: 'Northern Territory',
      },
      QLD: {
        integration_value: 'QLD(AUS)',
        label: 'Queensland',
      },
      SA: {
        integration_value: 'SA(AUS)',
        label: 'South Australia',
      },
      TAS: {
        integration_value: 'TAS(AUS)',
        label: 'Tasmania',
      },
      VIC: {
        integration_value: 'VIC(AUS)',
        label: 'Victoria',
      },
      WA: {
        integration_value: 'WA(AUS)',
        label: 'Western Australia',
      },
    },
  },
  AW: {
    integration_value: 'AW',
    label: 'Aruba',
    state_map: {},
  },
  AX: {
    integration_value: 'AX',
    label: 'Aland Islands',
    state_map: {},
  },
  AZ: {
    integration_value: 'AZ',
    label: 'Azerbaijan',
    state_map: {},
  },
  BA: {
    integration_value: 'BA',
    label: 'Bosnia and Herzegovina',
    state_map: {},
  },
  BB: {
    integration_value: 'BB',
    label: 'Barbados',
    state_map: {},
  },
  BD: {
    integration_value: 'BD',
    label: 'Bangladesh',
    state_map: {},
  },
  BE: {
    integration_value: 'BE',
    label: 'Belgium',
    state_map: {},
  },
  BF: {
    integration_value: 'Burkina Faso',
    label: 'Burkina Faso',
    state_map: {},
  },
  BG: {
    integration_value: 'BG',
    label: 'Bulgaria',
    state_map: {},
  },
  BH: {
    integration_value: 'BH',
    label: 'Bahrain',
    state_map: {},
  },
  BI: {
    integration_value: 'BI',
    label: 'Burundi',
    state_map: {},
  },
  BJ: {
    integration_value: 'BJ',
    label: 'Benin',
    state_map: {},
  },
  BM: {
    integration_value: 'Bermuda',
    label: 'Bermuda',
    state_map: {},
  },
  BN: {
    integration_value: 'BN',
    label: 'Brunei Darussalam',
    state_map: {},
  },
  BO: {
    integration_value: 'BO',
    label: 'Bolivia',
    state_map: {},
  },
  BQ: {
    integration_value: 'BQ',
    label: 'Bonaire, Saint Eustatius and Saba',
    state_map: {},
  },
  BR: {
    integration_value: 'BR',
    label: 'Brazil',
    state_map: {},
  },
  BS: {
    integration_value: 'BS',
    label: 'Bahamas',
    state_map: {},
  },
  BT: {
    integration_value: 'BT',
    label: 'Bhutan',
    state_map: {},
  },
  BV: {
    integration_value: 'BV',
    label: 'Bouvet Island',
    state_map: {},
  },
  BW: {
    integration_value: 'BW',
    label: 'Botswana',
    state_map: {},
  },
  CA: {
    integration_value: 'CA',
    label: 'Canada',
    state_map: {
      AB: {
        integration_value: 'AB',
        label: 'Alberta',
      },
      BC: {
        integration_value: 'BC',
        label: 'British Columbia',
      },
      MB: {
        integration_value: 'MB',
        label: 'Manitoba',
      },
      NB: {
        integration_value: 'NB',
        label: 'New Brunswick',
      },
      NL: {
        integration_value: 'NL',
        label: 'Newfoundland and Labrador',
      },
      NS: {
        integration_value: 'NS',
        label: 'Nova Scotia',
      },
      NT: {
        integration_value: 'NT',
        label: 'Northwest Territories',
      },
      NU: {
        integration_value: 'NU',
        label: 'Nunavut',
      },
      ON: {
        integration_value: 'ON',
        label: 'Ontario',
      },
      PE: {
        integration_value: 'PE',
        label: 'Prince Edward Island',
      },
      QC: {
        integration_value: 'QC',
        label: 'Quebec',
      },
      SK: {
        integration_value: 'SK',
        label: 'Saskatchewan',
      },
      YT: {
        integration_value: 'YT',
        label: 'Yukon',
      },
    },
  },
  CC: {
    integration_value: 'CC',
    label: 'Cocos (Keeling) Islands',
    state_map: {},
  },
  CD: {
    integration_value: 'CD',
    label: 'Congo (Democratic Republic)',
    state_map: {},
  },
  CF: {
    integration_value: 'CF',
    label: 'Central African Republic',
    state_map: {},
  },
  CG: {
    integration_value: 'CG',
    label: 'Congo (Republic)',
    state_map: {},
  },
  CH: {
    integration_value: 'CH',
    label: 'Switzerland',
    state_map: {},
  },
  CI: {
    integration_value: 'CI',
    label: "Cote d'Ivoire",
    state_map: {},
  },
  CL: {
    integration_value: 'CL',
    label: 'Chile',
    state_map: {},
  },
  CM: {
    integration_value: 'CM',
    label: 'Cameroon',
    state_map: {},
  },
  CN: {
    integration_value: 'CN',
    label: 'China',
    state_map: {},
  },
  CO: {
    integration_value: 'CO',
    label: 'Colombia',
    state_map: {},
  },
  CR: {
    integration_value: 'CR',
    label: 'Costa Rica',
    state_map: {},
  },
  CV: {
    integration_value: 'CV',
    label: 'Cape Verde',
    state_map: {},
  },
  CX: {
    integration_value: 'CX',
    label: 'Christmas Island',
    state_map: {},
  },
  CY: {
    integration_value: 'CY',
    label: 'Cyprus',
    state_map: {},
  },
  CZ: {
    integration_value: 'CZ',
    label: 'Czech Republic',
    state_map: {},
  },
  DE: {
    integration_value: 'DE',
    label: 'Germany',
    state_map: {},
  },
  DK: {
    integration_value: 'DK',
    label: 'Denmark',
    state_map: {},
  },
  DM: {
    integration_value: 'DM',
    label: 'Dominica',
    state_map: {},
  },
  DO: {
    integration_value: 'DO',
    label: 'Dominican Republic',
    state_map: {},
  },
  DZ: {
    integration_value: 'DZ',
    label: 'Algeria',
    state_map: {},
  },
  EC: {
    integration_value: 'EC',
    label: 'Ecuador',
    state_map: {},
  },
  EE: {
    integration_value: 'EE',
    label: 'Estonia',
    state_map: {},
  },
  EG: {
    integration_value: 'EG',
    label: 'Egypt',
    state_map: {},
  },
  EH: {
    integration_value: 'EH',
    label: 'Western Sahara',
    state_map: {},
  },
  ES: {
    integration_value: 'ES',
    label: 'Spain',
    state_map: {},
  },
  ET: {
    integration_value: 'ET',
    label: 'Ethiopia',
    state_map: {},
  },
  FI: {
    integration_value: 'FI',
    label: 'Finland',
    state_map: {},
  },
  FJ: {
    integration_value: 'FJ',
    label: 'Fiji',
    state_map: {},
  },
  FK: {
    integration_value: 'FK',
    label: 'Falkland Islands (Malvinas)',
    state_map: {},
  },
  FM: {
    integration_value: 'FM',
    label: 'Micronesia',
    state_map: {},
  },
  FR: {
    integration_value: 'FR',
    label: 'France',
    state_map: {},
  },
  GA: {
    integration_value: 'GA',
    label: 'Gabon',
    state_map: {},
  },
  GB: {
    integration_value: 'GB',
    label: 'United Kingdom',
    state_map: {},
  },
  GD: {
    integration_value: 'GD',
    label: 'Grenada',
    state_map: {},
  },
  GG: {
    integration_value: 'GG',
    label: 'Guernsey',
    state_map: {},
  },
  GH: {
    integration_value: 'GH',
    label: 'Ghana',
    state_map: {},
  },
  GL: {
    integration_value: 'GL',
    label: 'Greenland',
    state_map: {},
  },
  GM: {
    integration_value: 'GM',
    label: 'Gambia',
    state_map: {},
  },
  GN: {
    integration_value: 'GN',
    label: 'Guinea',
    state_map: {},
  },
  GP: {
    integration_value: 'GP',
    label: 'Guadeloupe',
    state_map: {},
  },
  GQ: {
    integration_value: 'GQ',
    label: 'Equatorial Guinea',
    state_map: {},
  },
  GR: {
    integration_value: 'GR',
    label: 'Greece',
    state_map: {},
  },
  GT: {
    integration_value: 'GT',
    label: 'Guatemala',
    state_map: {},
  },
  GU: {
    integration_value: 'GU',
    label: 'Guam',
    state_map: {},
  },
  GW: {
    integration_value: 'GW',
    label: 'Guinea-Bissau',
    state_map: {},
  },
  GY: {
    integration_value: 'GY',
    label: 'Guyana',
    state_map: {},
  },
  HN: {
    integration_value: 'HN',
    label: 'Honduras',
    state_map: {},
  },
  HR: {
    integration_value: 'HR',
    label: 'Croatia',
    state_map: {},
  },
  HT: {
    integration_value: 'HT',
    label: 'Haiti',
    state_map: {},
  },
  HU: {
    integration_value: 'HU',
    label: 'Hungary',
    state_map: {},
  },
  ID: {
    integration_value: 'ID',
    label: 'Indonesia',
    state_map: {},
  },
  IE: {
    integration_value: 'IE',
    label: 'Ireland',
    state_map: {},
  },
  IL: {
    integration_value: 'IL',
    label: 'Israel',
    state_map: {},
  },
  IN: {
    integration_value: 'IN',
    label: 'India',
    state_map: {},
  },
  IO: {
    integration_value: 'IO',
    label: 'British Indian Ocean Terr.',
    state_map: {},
  },
  IQ: {
    integration_value: 'IQ',
    label: 'Iraq',
    state_map: {},
  },
  IS: {
    integration_value: 'IS',
    label: 'Iceland',
    state_map: {},
  },
  IT: {
    integration_value: 'IT',
    label: 'Italy',
    state_map: {},
  },
  JM: {
    integration_value: 'JM',
    label: 'Jamaica',
    state_map: {},
  },
  JO: {
    integration_value: 'JO',
    label: 'Jordan',
    state_map: {},
  },
  JP: {
    integration_value: 'JP',
    label: 'Japan',
    state_map: {},
  },
  KE: {
    integration_value: 'KE',
    label: 'Kenya',
    state_map: {},
  },
  KG: {
    integration_value: 'KG',
    label: 'Kyrgyzstan',
    state_map: {},
  },
  KH: {
    integration_value: 'KH',
    label: 'Cambodia',
    state_map: {},
  },
  KI: {
    integration_value: 'KI',
    label: 'Kiribati',
    state_map: {},
  },
  KM: {
    integration_value: 'Comoros',
    label: 'Comoros',
    state_map: {},
  },
  KN: {
    integration_value: 'KN',
    label: 'Saint Kitts and Nevis',
    state_map: {},
  },
  KO: {
    integration_value: 'KO',
    label: 'Kosovo',
    state_map: {},
  },
  KR: {
    integration_value: 'KR',
    label: 'South Korea',
    state_map: {},
  },
  KW: {
    integration_value: 'KW',
    label: 'Kuwait',
    state_map: {},
  },
  KY: {
    integration_value: 'KY',
    label: 'Cayman Islands',
    state_map: {},
  },
  KZ: {
    integration_value: 'KZ',
    label: 'Kazakhstan',
    state_map: {},
  },
  LA: {
    integration_value: 'LA',
    label: 'Laos',
    state_map: {},
  },
  LB: {
    integration_value: 'LB',
    label: 'Lebanon',
    state_map: {},
  },
  LC: {
    integration_value: 'LC',
    label: 'Saint Lucia',
    state_map: {},
  },
  LI: {
    integration_value: 'LI',
    label: 'Liechtenstein',
    state_map: {},
  },
  LK: {
    integration_value: 'LK',
    label: 'Sri Lanka',
    state_map: {},
  },
  LR: {
    integration_value: 'LR',
    label: 'Liberia',
    state_map: {},
  },
  LT: {
    integration_value: 'LT',
    label: 'Lithuania',
    state_map: {},
  },
  LU: {
    integration_value: 'LU',
    label: 'Luxembourg',
    state_map: {},
  },
  LV: {
    integration_value: 'LV',
    label: 'Latvia',
    state_map: {},
  },
  MA: {
    integration_value: 'MA',
    label: 'Morocco',
    state_map: {},
  },
  MC: {
    integration_value: 'MC',
    label: 'Monaco',
    state_map: {},
  },
  MD: {
    integration_value: 'MD',
    label: 'Moldova',
    state_map: {},
  },
  ME: {
    integration_value: 'ME',
    label: 'Montenegro',
    state_map: {},
  },
  MG: {
    integration_value: 'MG',
    label: 'Madagascar',
    state_map: {},
  },
  MK: {
    integration_value: 'MK',
    label: 'Macedonia',
    state_map: {},
  },
  ML: {
    integration_value: 'ML',
    label: 'Mali',
    state_map: {},
  },
  MM: {
    integration_value: 'MM',
    label: 'Myanmar',
    state_map: {},
  },
  MN: {
    integration_value: 'MN',
    label: 'Mongolia',
    state_map: {},
  },
  MO: {
    integration_value: 'MO',
    label: 'Macao',
    state_map: {},
  },
  MR: {
    integration_value: 'MR',
    label: 'Mauritania',
    state_map: {},
  },
  MS: {
    integration_value: 'MS',
    label: 'Montserrat',
    state_map: {},
  },
  MU: {
    integration_value: 'MU',
    label: 'Mauritius',
    state_map: {},
  },
  MW: {
    integration_value: 'MW',
    label: 'Malawi',
    state_map: {},
  },
  MX: {
    integration_value: 'MX',
    label: 'Mexico',
    state_map: {},
  },
  MY: {
    integration_value: 'MY',
    label: 'Malaysia',
    state_map: {},
  },
  MZ: {
    integration_value: 'MZ',
    label: 'Mozambique',
    state_map: {},
  },
  NA: {
    integration_value: 'NA',
    label: 'Namibia',
    state_map: {},
  },
  NE: {
    integration_value: 'Niger',
    label: 'Niger',
    state_map: {},
  },
  NG: {
    integration_value: 'NG',
    label: 'Nigeria',
    state_map: {},
  },
  NI: {
    integration_value: 'NI',
    label: 'Nicaragua',
    state_map: {},
  },
  NL: {
    integration_value: 'NL',
    label: 'Netherlands',
    state_map: {},
  },
  NO: {
    integration_value: 'NO',
    label: 'Norway',
    state_map: {},
  },
  NP: {
    integration_value: 'NP',
    label: 'Nepal',
    state_map: {},
  },
  NR: {
    integration_value: 'NR',
    label: 'Nauru',
    state_map: {},
  },
  NZ: {
    integration_value: 'NZ',
    label: 'New Zealand',
    state_map: {},
  },
  OM: {
    integration_value: 'OM',
    label: 'Oman',
    state_map: {},
  },
  PA: {
    integration_value: 'PA',
    label: 'Panama',
    state_map: {},
  },
  PE: {
    integration_value: 'PE',
    label: 'Peru',
    state_map: {},
  },
  PG: {
    integration_value: 'PG',
    label: 'Papua New Guinea',
    state_map: {},
  },
  PH: {
    integration_value: 'PH',
    label: 'Philippines',
    state_map: {},
  },
  PK: {
    integration_value: 'PK',
    label: 'Pakistan',
    state_map: {},
  },
  PL: {
    integration_value: 'PL',
    label: 'Poland',
    state_map: {},
  },
  PS: {
    integration_value: 'PS',
    label: 'Palestinian Territory',
    state_map: {},
  },
  PT: {
    integration_value: 'PT',
    label: 'Portugal',
    state_map: {},
  },
  PY: {
    integration_value: 'PY',
    label: 'Paraguay',
    state_map: {},
  },
  QA: {
    integration_value: 'QA',
    label: 'Qatar',
    state_map: {},
  },
  RO: {
    integration_value: 'RO',
    label: 'Romania',
    state_map: {},
  },
  RS: {
    integration_value: 'RS',
    label: 'Serbia',
    state_map: {},
  },
  RU: {
    integration_value: 'RU',
    label: 'Russian Federation',
    state_map: {},
  },
  RW: {
    integration_value: 'RW',
    label: 'Rwanda',
    state_map: {},
  },
  SA: {
    integration_value: 'SA',
    label: 'Saudi Arabia',
    state_map: {},
  },
  SB: {
    integration_value: 'SB',
    label: 'Solomon Islands',
    state_map: {},
  },
  SC: {
    integration_value: 'SC',
    label: 'Seychelles',
    state_map: {},
  },
  SE: {
    integration_value: 'SE',
    label: 'Sweden',
    state_map: {},
  },
  SH: {
    integration_value: 'SH',
    label: 'Saint Helena',
    state_map: {},
  },
  SI: {
    integration_value: 'SI',
    label: 'Slovenia',
    state_map: {},
  },
  SK: {
    integration_value: 'SK',
    label: 'Slovakia',
    state_map: {},
  },
  SL: {
    integration_value: 'SL',
    label: 'Sierra Leone',
    state_map: {},
  },
  SM: {
    integration_value: 'SM',
    label: 'San Marino',
    state_map: {},
  },
  SN: {
    integration_value: 'SN',
    label: 'Senegal',
    state_map: {},
  },
  SO: {
    integration_value: 'SO',
    label: 'Somalia',
    state_map: {},
  },
  SR: {
    integration_value: 'SR',
    label: 'Suriname',
    state_map: {},
  },
  SS: {
    integration_value: 'SS',
    label: 'South Sudan',
    state_map: {},
  },
  ST: {
    integration_value: 'ST',
    label: 'Sao Tome and Principe',
    state_map: {},
  },
  SV: {
    integration_value: 'SV',
    label: 'El Salvador',
    state_map: {},
  },
  SZ: {
    integration_value: 'SZ',
    label: 'Swaziland',
    state_map: {},
  },
  TD: {
    integration_value: 'TD',
    label: 'Chad',
    state_map: {},
  },
  TG: {
    integration_value: 'TG',
    label: 'Togo',
    state_map: {},
  },
  TH: {
    integration_value: 'TH',
    label: 'Thailand',
    state_map: {},
  },
  TJ: {
    integration_value: 'TJ',
    label: 'Tajikistan',
    state_map: {},
  },
  TM: {
    integration_value: 'TM',
    label: 'Turkmenistan',
    state_map: {},
  },
  TN: {
    integration_value: 'TN',
    label: 'Tunisia',
    state_map: {},
  },
  TO: {
    integration_value: 'TO',
    label: 'Tonga',
    state_map: {},
  },
  TR: {
    integration_value: 'TR',
    label: 'Turkey',
    state_map: {},
  },
  TT: {
    integration_value: 'TT',
    label: 'Trinidad and Tobago',
    state_map: {},
  },
  TW: {
    integration_value: 'TW',
    label: 'Taiwan',
    state_map: {},
  },
  TZ: {
    integration_value: 'TZ',
    label: 'Tanzania',
    state_map: {},
  },
  UG: {
    integration_value: 'UG',
    label: 'Uganda',
    state_map: {},
  },
  US: {
    integration_value: 'US',
    label: 'USA',
    state_map: {
      AK: {
        integration_value: 'AK',
        label: 'Alaska',
      },
      AL: {
        integration_value: 'AL',
        label: 'Alabama',
      },
      AR: {
        integration_value: 'AR',
        label: 'Arkansas',
      },
      AZ: {
        integration_value: 'AZ',
        label: 'Arizona',
      },
      CA: {
        integration_value: 'CA',
        label: 'California',
      },
      CO: {
        integration_value: 'CO',
        label: 'Colorado',
      },
      CT: {
        integration_value: 'CT',
        label: 'Connecticut',
      },
      DC: {
        integration_value: 'DC',
        label: 'District of Columbia',
      },
      DE: {
        integration_value: 'DE',
        label: 'Delaware',
      },
      FL: {
        integration_value: 'FL',
        label: 'Florida',
      },
      GA: {
        integration_value: 'GA',
        label: 'Georgia',
      },
      HI: {
        integration_value: 'HI',
        label: 'Hawaii',
      },
      IA: {
        integration_value: 'IA',
        label: 'Iowa',
      },
      ID: {
        integration_value: 'ID',
        label: 'Idaho',
      },
      IL: {
        integration_value: 'IL',
        label: 'Illinois',
      },
      IN: {
        integration_value: 'IN',
        label: 'Indiana',
      },
      KS: {
        integration_value: 'KS',
        label: 'Kansas',
      },
      KY: {
        integration_value: 'KY',
        label: 'Kentucky',
      },
      LA: {
        integration_value: 'LA',
        label: 'Louisiana',
      },
      MA: {
        integration_value: 'MA',
        label: 'Massachusetts',
      },
      MD: {
        integration_value: 'MD',
        label: 'Maryland',
      },
      ME: {
        integration_value: 'ME',
        label: 'Maine',
      },
      MI: {
        integration_value: 'MI',
        label: 'Michigan',
      },
      MN: {
        integration_value: 'MN',
        label: 'Minnesota',
      },
      MO: {
        integration_value: 'MO',
        label: 'Missouri',
      },
      MS: {
        integration_value: 'MS',
        label: 'Mississippi',
      },
      MT: {
        integration_value: 'MT',
        label: 'Montana',
      },
      NC: {
        integration_value: 'NC',
        label: 'North Carolina',
      },
      ND: {
        integration_value: 'ND',
        label: 'North Dakota',
      },
      NE: {
        integration_value: 'NE',
        label: 'Nebraska',
      },
      NH: {
        integration_value: 'NH',
        label: 'New Hampshire',
      },
      NJ: {
        integration_value: 'NJ',
        label: 'New Jersey',
      },
      NM: {
        integration_value: 'NM',
        label: 'New Mexico',
      },
      NV: {
        integration_value: 'NV',
        label: 'Nevada',
      },
      NY: {
        integration_value: 'NY',
        label: 'New York',
      },
      OH: {
        integration_value: 'OH',
        label: 'Ohio',
      },
      OK: {
        integration_value: 'OK',
        label: 'Oklahoma',
      },
      OR: {
        integration_value: 'OR',
        label: 'Oregon',
      },
      PA: {
        integration_value: 'PA',
        label: 'Pennsylvania',
      },
      RI: {
        integration_value: 'RI',
        label: 'Rhode Island',
      },
      SC: {
        integration_value: 'SC',
        label: 'South Carolina',
      },
      SD: {
        integration_value: 'SD',
        label: 'South Dakota',
      },
      TN: {
        integration_value: 'TN',
        label: 'Tennessee',
      },
      TX: {
        integration_value: 'TX',
        label: 'Texas',
      },
      UT: {
        integration_value: 'UT',
        label: 'Utah',
      },
      VA: {
        integration_value: 'VA',
        label: 'Virginia',
      },
      VT: {
        integration_value: 'VT',
        label: 'Vermont',
      },
      WA: {
        integration_value: 'WA',
        label: 'Washington',
      },
      WI: {
        integration_value: 'WI',
        label: 'Wisconsin',
      },
      WV: {
        integration_value: 'WV',
        label: 'West Virginia',
      },
      WY: {
        integration_value: 'WY',
        label: 'Wyoming',
      },
    },
  },
  UY: {
    integration_value: 'UY',
    label: 'Uruguay',
    state_map: {},
  },
  UZ: {
    integration_value: 'UZ',
    label: 'Uzbekistan',
    state_map: {},
  },
  VC: {
    integration_value: 'VC',
    label: 'Saint Vincent',
    state_map: {},
  },
  VE: {
    integration_value: 'VE',
    label: 'Venezuela',
    state_map: {},
  },
  VN: {
    integration_value: 'Vietnam',
    label: 'Vietnam',
    state_map: {},
  },
  VU: {
    integration_value: 'VU',
    label: 'Vanuatu',
    state_map: {},
  },
  YE: {
    integration_value: 'YE',
    label: 'Yemen',
    state_map: {},
  },
  ZA: {
    integration_value: 'ZA',
    label: 'South Africa',
    state_map: {},
  },
  ZM: {
    integration_value: 'ZM',
    label: 'Zambia',
    state_map: {},
  },
  ZW: {
    integration_value: 'Zimbabwe',
    label: 'Zimbabwe',
    state_map: {},
  },
  AI: {
    integration_value: 'AI',
    label: 'Anguilla',
    state_map: {},
  },
  AN: {
    integration_value: 'AN',
    label: 'Netherlands Antilles',
    state_map: {},
  },
  AQ: {
    integration_value: 'AQ',
    label: 'Antarctica',
    state_map: {},
  },
  BL: {
    integration_value: 'BL',
    label: 'Saint Barth\u00e9lemy',
    state_map: {},
  },
  BY: {
    integration_value: 'BY',
    label: 'Belarus',
    state_map: {},
  },
  BZ: {
    integration_value: 'BZ',
    label: 'Belize',
    state_map: {},
  },
  CK: {
    integration_value: 'CK',
    label: 'Cook Islands',
    state_map: {},
  },
  CW: {
    integration_value: 'CW',
    label: 'Curacao',
    state_map: {},
  },
  DJ: {
    integration_value: 'DJ',
    label: 'Djibouti',
    state_map: {},
  },
  ER: {
    integration_value: 'ER',
    label: 'Eritrea',
    state_map: {},
  },
  FO: {
    integration_value: 'FO',
    label: 'Faroe Islands',
    state_map: {},
  },
  GE: {
    integration_value: 'GE',
    label: 'Georgia',
    state_map: {},
  },
  GF: {
    integration_value: 'GF',
    label: 'French Guiana',
    state_map: {},
  },
  GI: {
    integration_value: 'GI',
    label: 'Gibraltar',
    state_map: {},
  },
  HK: {
    integration_value: 'HK',
    label: 'Hong Kong',
    state_map: {},
  },
  HM: {
    integration_value: 'HM',
    label: 'Heard & McDonald Islands',
    state_map: {},
  },
  JE: {
    integration_value: 'JE',
    label: 'Jersey',
    state_map: {},
  },
  LS: {
    integration_value: 'LS',
    label: 'Lesotho',
    state_map: {},
  },
  MF: {
    integration_value: 'MF',
    label: 'Saint Martin',
    state_map: {},
  },
  MH: {
    integration_value: 'MH',
    label: 'Marshall Islands',
    state_map: {},
  },
  MQ: {
    integration_value: 'MQ',
    label: 'Martinique',
    state_map: {},
  },
  MT: {
    integration_value: 'MT',
    label: 'Malta',
    state_map: {},
  },
  MV: {
    integration_value: 'MV',
    label: 'Maldives',
    state_map: {},
  },
  NC: {
    integration_value: 'NC',
    label: 'New Caledonia',
    state_map: {},
  },
  NF: {
    integration_value: 'NF',
    label: 'Norfolk Island',
    state_map: {},
  },
  NU: {
    integration_value: 'NU',
    label: 'Niue',
    state_map: {},
  },
  PF: {
    integration_value: 'PF',
    label: 'French Polynesia',
    state_map: {},
  },
  PM: {
    integration_value: 'PM',
    label: 'Saint Pierre and Miquelon',
    state_map: {},
  },
  PN: {
    integration_value: 'PN',
    label: 'Pitcairn',
    state_map: {},
  },
  PR: {
    integration_value: 'PR',
    label: 'Puerto Rico',
    state_map: {},
  },
  PW: {
    integration_value: 'PW',
    label: 'Palau',
    state_map: {},
  },
  RE: {
    integration_value: 'RE',
    label: 'Reunion',
    state_map: {},
  },
  SG: {
    integration_value: 'SG',
    label: 'Singapore',
    state_map: {},
  },
  SJ: {
    integration_value: 'SJ',
    label: 'Svalbard And Jan Mayen',
    state_map: {},
  },
  SX: {
    integration_value: 'SX',
    label: 'Sint Maarten(Dutch part)',
    state_map: {},
  },
  TC: {
    integration_value: 'TC',
    label: 'Turks and Caicos Islands',
    state_map: {},
  },
  TF: {
    integration_value: 'TF',
    label: 'French Southern Territories',
    state_map: {},
  },
  TK: {
    integration_value: 'TK',
    label: 'Tokelau',
    state_map: {},
  },
  TP: {
    integration_value: 'TP',
    label: 'Timor-Leste',
    state_map: {},
  },
  TV: {
    integration_value: 'TV',
    label: 'Tuvalu',
    state_map: {},
  },
  VA: {
    integration_value: 'VA',
    label: 'Vatican City State (Holy See)',
    state_map: {},
  },
  VG: {
    integration_value: 'VG',
    label: 'British Virgin Islands',
    state_map: {},
  },
  VI: {
    integration_value: 'VI',
    label: 'U.S. Virgin Islands',
    state_map: {},
  },
  WALES: {
    integration_value: 'Wales',
    label: 'Wales',
    state_map: {},
  },
  WF: {
    integration_value: 'WF',
    label: 'Wallis and Futuna',
    state_map: {},
  },
  WS: {
    integration_value: 'WS',
    label: 'Samoa',
    state_map: {},
  },
  YT: {
    integration_value: 'YT',
    label: 'Mayotte',
    state_map: {},
  },
};

export const IMPLIED_OPTIN_COUNTRIES = [
  'US',
  'GB',
  'FR',
  'SE',
  'IE',
  'BE',
  'FI',
  'HU',
  'PL',
];

const PRIORITY_COUNTRY_LIST = ['US', 'AU', 'CA', 'DE', 'GB'];
const NON_PRIORITY_COUNTRY_LIST = Object.keys(COUNTRY_STATE_MAP)
  .filter((country) => !PRIORITY_COUNTRY_LIST.includes(country))
  .sort((a, b) =>
    COUNTRY_STATE_MAP[a].label > COUNTRY_STATE_MAP[b].label ? 1 : -1
  );

export const COUNTRY_OPTIONS = [
  ...PRIORITY_COUNTRY_LIST.map((iso_code) => {
    return {
      value: iso_code,
      label: COUNTRY_STATE_MAP[iso_code].label,
    };
  }),
  ...NON_PRIORITY_COUNTRY_LIST.map((iso_code) => {
    return {
      value: iso_code,
      label: COUNTRY_STATE_MAP[iso_code].label,
    };
  }),
];

export const getStateOptions = (countryCode) => {
  const country = COUNTRY_STATE_MAP[countryCode];
  if (!country || !country.state_map) {
    return [];
  }
  return Object.keys(country.state_map)
    .map((stateCode) => {
      return {
        value: stateCode,
        label: country.state_map[stateCode].label,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
